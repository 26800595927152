import { useEffect, useState } from 'react';
import styles from '../styles/style.module.css';
import { getRegions, getCountries, getBundles } from "../api";
import { BUNDLE_TYPE } from '../../../utils/constants';
import { FilterIcon, } from '../../../assets/images';
import MainLoder from '../../../components/mainLoader'
import search from "../../../assets/images/search.png"
import {
    Breadcrumb,
    Layout,
    BundleCard,
    PartnerSection,
} from '../../../components';
import MainLoader from '../../../components/mainLoader';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../../components/pagination';
import { decodeBase64 } from '../../../utils/secureToken';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import chit from "../../../assets/images/chit.png"
import left from "../../../assets/images/left.png"
import tower from "../../../assets/images/tower.png"
import { getCoupons } from '../../home/api';

interface Params {
    regionType: number;
    limit: number;
    page: number;
    search: string;
    countryId?: string; 
    couponId? : string;

}

const Esim = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const couponId = queryParams.get('couponId');
    let countryId = queryParams.get('country');
    let topup: any = queryParams.get('topup');
    topup = decodeBase64(topup)
    const couponPlans = location?.state ? location?.state?.couponPlans : null
    const [countries, setCountries] = useState<any[]>([]);
    const [bundles, setBundles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const currency = useSelector(((state: any) => state?.getCurrency?.currency));
    const [activeTab, setActiveTab] = useState<number>(1);
    const [counts, setCounts] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const [activeCoupon, setActiveCoupon] = useState<any>({});
    const [couponId, setCouponId] = useState<any>();
    const [getCouponDetals, setGetCouponDetals] = useState<any>([]);

    const topupDetails = topup && !topup?.error ? JSON.parse(topup) : '';

    const [searchParams, setSearchParams] = useState('');

    const handleSearchSubmit = (event: any) => {
        event.preventDefault();
        setSearchParams(event.target.value);
        if (event.target.value) {
            setCurrentPage(1);
            if (countryId || topupDetails?.country) {
                navigate(`/esim`, { replace: false });
            }
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        window.scrollTo(600, 600)
    };

    const pageRecords = 12
    useEffect(()=>{
        getCoupons().then((res:any)=>{
            setGetCouponDetals(res?.data)
        }).catch((error)=> console.log(error))
    }, [])

    useEffect(() => {
        if (topup && topup?.error) {
            toast.error("Topup not exist, something went wrong!")
            setTimeout(() => {
                navigate(-2);
            }, 3000)
        }
        const params: Params = {
            regionType: activeTab,
            limit: pageRecords,
            page: currentPage,
            search: searchParams || ""
        };
        if (countryId || topupDetails?.country) {
            params.countryId = countryId ? countryId :  topupDetails?.country
            setSearchParams('');
            params.regionType = 1;
            setActiveTab(1)
            window.scrollTo(600, 600)
        }
        if (searchParams) {
            params.search = searchParams
            setLoader(false)
        } else {
            setLoader(true)
            // couponId
        }
        if(couponId) {
            params.couponId = couponId            
        }

        getBundles(params).then(res => {
            setCounts(res.data.count)
            setBundles(res.data.bundles);
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        });

    }, [activeTab, currency, currentPage, countryId, searchParams, couponId]);

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,  
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    autoplay: true,  // Enables auto sliding
                    autoplaySpeed: 1000,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,  // Enables auto sliding
                    autoplaySpeed: 1000,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Layout>
            {loader &&
                <MainLoder />
            }
            <Breadcrumb />
            <div className={styles.esimBanner}>
                <div className='container'>
                    <div className={styles.esimBannerOuter}>
                        <h4>Commbitz eSIM stay connected anytime, anywhere!</h4>
                    </div>
                </div>
            </div>

            <section>

                <div className='container'>
                    {!topup && getCouponDetals && getCouponDetals?.length > 0 &&
                        <div className="mainCoupon">
                            <div className="row">
                                <div className={styles.PackHeadLeft}>
                                    <h4>Coupons</h4>
                                </div>
                                {couponId && activeCoupon &&
                                <div className="col-3">
                                    <div className={styles.esimDetails} >
                                                <div className={styles.FirtPack}>
                                                    <div className={styles.PackHead}>
                                                        <div className={styles.PackHeadLeft}>
                                                            <h4>{activeCoupon?.title}</h4>
                                                        </div>
                                                        <div className={styles.PackHeadRight}>
                                                            <span>OFF {activeCoupon?.discount}%</span>
                                                        </div>
                                                    </div>
                                                    <button className={styles.buySimBtn} onClick={() => {
                                                        setActiveCoupon({})
                                                        setCouponId('')
                                                    }}>
                                                        {couponId == activeCoupon?._id ? 'Deactive' : 'Active'}
                                                    </button>
                                                </div>
                                            </div>
                                </div>
                                }                                
                                <div className={`${couponId ? 'col-9' : 'col-12'}`}>
                                    <Slider {...settings}>
                                        {getCouponDetals.map((list: any) => (
                                            <div className={styles.esimDetails} >
                                                <div className={styles.FirtPack}>
                                                    <div className={styles.PackHead}>
                                                        <div className={styles.PackHeadLeft}>
                                                            <h4>{list?.title}</h4>
                                                        </div>
                                                        <div className={styles.PackHeadRight}>
                                                            <span>OFF {list?.discount}%</span>
                                                        </div>
                                                    </div>
                                                    <button className={styles.buySimBtn} onClick={() => {
                                                        setActiveCoupon(list)
                                                        setCouponId(list?._id)
                                                    }} disabled={couponId == list?._id ? true : false}>
                                                        {couponId == list?._id ? 'Deactive' : 'Active'}
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>

                        </div>
                    }

                    <div className={styles.esminFilter}>
                        <div className={styles.FormGroup}>
                            <form className={styles.FormGroup} onSubmit={handleSearchSubmit}>
                                <input
                                    type="text"
                                    placeholder="Search here.."
                                    value={searchParams}
                                    onChange={handleSearchSubmit}
                                    style={{ color: "#ffffff" }}
                                />
                                <button type="submit">
                                    <img src={search} alt="search icon" />
                                </button>
                            </form>
                        </div>
                        <div className={styles.esimBlogsfl}>
                            <ul>
                                <li className={activeTab === 1 ? styles.active : ""}
                                    onClick={() => {
                                        setActiveTab(1);
                                        setSearchParams('');
                                        handlePageChange(1);
                                        if (countryId || topupDetails?.country) {
                                            navigate(`/esim`, { replace: false });
                                        }
                                    }}>
                                    <span>Local eSIM</span>
                                </li>
                                <li className={activeTab === 2 ? styles.active : ""}
                                    onClick={() => {
                                        setActiveTab(2);
                                        setSearchParams('');
                                        handlePageChange(1)
                                        if (countryId || topupDetails?.country) {
                                            navigate(`/esim`, { replace: false });
                                        }
                                    }}>  <span>MultiCountry</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {loader && <MainLoader />}
                    <div className='udadEsin'>
                    <div className={styles.esimBlogs}>
                        <div className='row'>
                            {bundles.length > 0 ?
                                bundles.map((bundle, index) =>
                                    <BundleCard key={index} bundle={bundle} coupon={couponId} topup={topupDetails?.iccid ? topupDetails?.iccid : ''} />
                                )
                                :
                                <div className={styles.notFount}>Esim not Found!</div>
                            }
                        </div>
                    </div>
                    </div>

                </div>
                <Pagination
                    count={counts} // You can replace this with the actual count received from the API
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    itemsPerPage={pageRecords}
                />
            </section>
            <PartnerSection />
        </Layout>
    );
};

export default Esim;