import styles from "../styles/style.module.css";

interface FeaturesShowcasseCardProps {
  showSrc: string;
}

const FeaturesShowcasseCard: React.FC<FeaturesShowcasseCardProps> = ({ showSrc }) => {
  return (
    <div className='col-md-4'>
      <div className={styles.showcaseImage}>
        <img src={showSrc} alt="" />
        <h4>Redefined Roaming</h4>
        <h5>Global, Multiverse, local and regional, voice and data sim Plans. </h5>
      </div>
    </div>
  )
}

export default FeaturesShowcasseCard