import { useEffect, useState } from "react";
import { useParams } from "react-router";

import styles from "../styles/style.module.css";
import {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
} from "../../../redux/slices/userSlice";
import MainLoader from "../../../components/mainLoader";
// Components
import Layout from "../../../components/layout";
import StripePaymentModal from "../../../components/modals/StripePayment";

//API
import {
  getOrderDetails,
  getPromos,
  purchaseOrder,
  razorpayAddOrder,
  stripePaymentIntent,
  updateOrder,
} from "../api";

// Images
import {
  ApplePayIcon,
  CheckoutIcon,
  CreditCardPayIcon,
} from "../../../assets/images";
import Gpay from "../../../assets/images/Gpay.png";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../redux/api";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENT_METHOD } from "../../../utils/constants";
import useAuth from "../../../lib/hooks/useAuth";
import { decodeBase64 } from "../../../utils/secureToken";
import { NIYO_URL } from "../../../config";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const Checkout = () => {
  let params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentGateway: any = queryParams.get("paymentGateway");
  const urlToken: any = queryParams.get("token");

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const { data } = useSelector((state: any) => state.getUserData);
  const [order, setOrderDetails] = useState<any>({});
  const [promoCode, setPromoCode] = useState<string>("");
  const [promos, setPromos] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [promoError, setPromoError] = useState<string>("");
  const [paymentDetails, setPaymentDetails] = useState({
    clientSecret: "",
    paymentId: "",
    setupIntent: "",
    currency: '',
    label: 'Total',
    amount: 0
  });
  const [selectedPaymentMethod, setPaymentMethod] = useState<number>(2);
  const [minimumSpend, setMinimumSpend] = useState<any>(0);
  const [paymentMethodError, setPaymentMethodError] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(true);
  const [finalPrice, setFinalPrice] = useState(0);
  const [walletAmShow, setWalletAmShow] = useState(false);
  // const [walletAmError, setWalletAmError] = useState('');
  const [walletMethod, setWalletMethod] = useState(0);
  const [walletInput, setWalletInput] = useState(false);
  const [pageError, setPageError] = useState("");
  const currency = useSelector((state: any) => state?.getCurrency?.currency);
  const current_url: any = window.localStorage.getItem("current_url");
  // const [paymentOption, setPaymentOption] = useState(''); // Initially empty
  // const [walletAmt, setWalletAmt] = useState('');
  // const [walletAmtError, setWalletAmtError] = useState(''); // Initially empty

  const secureToken: any = decodeBase64(urlToken);

  useEffect(() => {

    if (urlToken && secureToken?.error) {
      window.localStorage.removeItem("niyoToken")
      if (secureToken?.error) toast.error("Token not exist")

      setTimeout(() => {
        window.location.href = NIYO_URL
      }, 5000)
    }
    if (urlToken && !secureToken?.error)
      window.localStorage.setItem("niyoToken", secureToken);

    // paymentGateway == PAYMENT_METHOD.RAZORPAY
    //   ? setPaymentMethod(3)
    //   : setPaymentMethod(2);

    if (current_url) {
      const payload: any = {
        orderId: params.id as string,
      };

      updateOrder(payload).then((res) => {
        window.localStorage.removeItem("current_url");
        setLoader(false);
      });
    }
    getOrderDetails(params.id as string)
      .then((res: any) => {

        if (res?.data?.finalPrice <= 0) {
          setPaymentMethod(0)
        } else {
          setPaymentMethod(2)
        }

        setFinalPrice(res?.data?.finalPrice);
        if (urlToken && secureToken != null) setPromoCode(res?.data?.promoCode);
        // If everything is fine, set the order details
        setOrderDetails(res?.data);
      })
      .catch((error) => {
        if (error?.data?.statusCode === 400) {
          setPageError("Plans is not found!");
        }
      })
      .finally(() => {
        // This block will execute regardless of whether the promise was fulfilled or rejected
        setLoader(false);
      });
    if (isAuthenticated && !urlToken)
      getPromos().then((res) => {
        setPromos(res?.data);
        setLoader(false);
      });
    window.scrollTo(0, 0);
  }, [params, currency, isAuthenticated]);


  const increaseQuantity = async () => {
    setLoader(true);
    const payload = {
      orderId: params.id as string,
      quantity: order.quantity + 1,
      promoCode,
    };

    updateOrder(payload).then((res) => {
      setFinalPrice(res?.data?.finalPrice);
      if (res?.data?.finalPrice <= 0) {
        setPaymentMethod(0)
      } else {
        setPaymentMethod(2)
      }
      setOrderDetails(res.data);
      setLoader(false);
    });
  };

  const decreaseQuantity = async () => {
    setLoader(true);
    if (order.quantity > 1) {
      const qty = order.quantity - 1;
      const payload: any = {
        orderId: params.id as string,
        quantity: qty,
        promoCode: promoCode, // Assuming order.promoCode is defined somewhere
      };

      if (minimumSpend >= order.planPrice / qty) {
        delete payload.promoCode;
      }
      updateOrder(payload).then((res) => {
        setFinalPrice(res?.data?.finalPrice);
        if (res?.data?.finalPrice <= 0) {
          setPaymentMethod(0)
        } else {
          setPaymentMethod(2)
        }
        setOrderDetails(res.data);
        setLoader(false);
      });
    } else {
      setLoader(false);
    }
  };


  const handleCheckout = async () => {
    setLoader(true);
    const payload = { orderId: params.id as string };

    if (selectedPaymentMethod === 1 || selectedPaymentMethod === 2 || selectedPaymentMethod === 4) {
      stripePaymentIntent(payload)
        .then((res: any) => {
          setPaymentDetails({
            clientSecret: res.data.paymentIntentSecretKey,
            paymentId: res.data.paymentId,
            setupIntent: res.data.setupIntent,
            currency: res.data.currency.toLowerCase(),
            label: 'Total',
            amount: res.data.amount,
          });
          setLoader(false);
          setShow(true);
        })
        .catch((error: any) => {
          setLoader(false);
          toast.error(error?.data?.message  || "Something went wrong!")
        });
    } else if (selectedPaymentMethod === 3) {
      razorpayAddOrder(payload).then((res) => {
        var options = {
          key: res.data.razorPayKeyId,
          order_id: res.data.razorPayOrderId,
          handler: function (response: any) {
            // console.log(response, " jkkjkj ")
            // window.location.href = "/payment";
          },
          prefill: {
            name: data?.fullName,
            email: data?.email,
            contact: data?.phoneNumber,
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
      });
      setLoader(false);
    } else {
      setLoader(true);
      const payload: any = {
        orderId: params.id as string,
        status: 2 as number
      }
      purchaseOrder(payload).then(res => {
        window.location.href = `${NIYO_URL}?status=true`
        setLoader(false);
      }).catch(error => {
        console.log(error)
        setLoader(false);
        toast.error(error?.data?.message  || "Something went wrong!")
      })
      setPaymentMethodError("Please select payment method");

    }
  };

  const handleAddPromo = ({ promo, minimumSpend }: any) => {
    setPromoError("");
    setLoader(true);
    const payload = {
      orderId: params.id as string,
      quantity: order?.quantity,
      promoCode:
        order?.promoApplied === false || order?.promoId !== promo?._id
          ? promo?.promoCode
          : undefined,
    };
    setMinimumSpend(minimumSpend);
    // if (minimumSpend >= order?.planPrice) {
    //   setPromoError(`Please expand minimum spend amount to ${minimumSpend}`)
    //   setLoader(false)
    //   return null
    // }

    setPromoCode(promo?.promoCode);
    toast.promise(updateOrder(payload), {
      pending: {
        render() {
          return "Updating Order";
        },
      },
      success: {
        render({ data }) {
          setOrderDetails(data.data);
          setLoader(false);
          if (data?.data?.promoApplied) {
            return "Promo applied successfully";
          } else {
            setPromoCode("");
            return "Promo removed successfully";
          }
        },
      },
      error: {
        render({ data }: any) {
          setLoader(false);
          return data.data.message;
        },
      },
    });
  };

  const userData = async () => {
    dispatch(fetchDataStart());
    try {
      const userData = await getUserData();
      dispatch(fetchDataSuccess(userData?.data));
      setLoader(false);
    } catch (error: any) {
      dispatch(fetchDataFailure(error.message));
    }
  };

  const handlePaymentMethod = (methodId: number) => {
    // setLoader(true)
    // userData()
    setPaymentMethod(methodId);
    setWalletAmShow(false);
    setWalletInput(false);
    setWalletMethod(0);
  };
  const handleSearchSubmit = (event: any) => {
    event.preventDefault();
    if (event.type === "change") {
      setPromoCode(event.target.value);
    } else if (event.type === "submit") {
      event.preventDefault();

      const payload: any = {
        quantity: order.quantity,
        orderId: params.id as string,
        promoCode: promoCode,
      };
      toast.promise(updateOrder(payload), {
        pending: {
          render() {
            return "Updating Order";
          },
        },
        success: {
          render({ data }) {
            setOrderDetails(data.data);
            setLoader(false);
            return "Promo applied successfully";
          },
        },
        error: {
          render({ data }: any) {
            setLoader(false);
            return data.data.message;
          },
        },
      });
    }
  };
  // const handleWalletPayment = (walletAm: any) => {
  //     if(walletAm > 0) {
  //         setWalletAmShow(true)
  //     } else {
  //         setWalletAmError("Your wallet amount is 0")
  //     }
  // }

  // const handlePaymentChange = (event:any) => {
  //     const checkVal = event.target.value
  //     setWalletMethod(checkVal);
  //     if(checkVal == 2) {
  //         setWalletInput(true)
  //     } else {
  //         setWalletInput(false)
  //     }
  // };

  // // Event handler for wallet amount change
  // const handleWalletChange = (event?:any) => {
  //     const amt = (event?.target?.value ?? '').replace(/[^0-9.]/g, '');
  //     setWalletAmt(amt);
  // };

  // const walletUpdate = (values : any) => {
  //       toast.promise(
  //         walletAmtUpdate({
  //             "walletAmount": values.walletAm,
  //             "orderId": values.id
  //           }),
  //         {
  //             pending: {
  //                 render() {
  //                     return 'trying to wallet amount debit';
  //                 }
  //             },
  //             success: {
  //                 render({ data }) {
  //                     return 'Wallet payment successfully debits';
  //                 }
  //             },
  //             error: {
  //                 render({ data }: any) {
  //                     return data.data.message;
  //                 }
  //             }
  //         });

  // }
  // // Event handler for form submission
  // const handleSubmit = (event:any) => {
  //     event.preventDefault();
  //     const walletAmt = data?.wallet;
  //     const finalAmt = order.finalPrice
  //     if(walletMethod === 1) {
  //         if(walletAmt < finalAmt ) {
  //             setWalletAmtError("You can't paymnt because your wallet balnce  is low, You can you ")
  //         }
  //         walletUpdate({
  //             walletAm : finalAmt,
  //             id : params.id
  //         })

  //     }
  // };

  const isAndroidOrIOS = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return (
    <Layout>
      {loader && <MainLoader />}
      <div className={styles.checkoutpage}>
        <div className="container">
          <h3>Secure Checkout</h3>
          {pageError ? (
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8" style={{ color: "#fff" }}>
                {pageError}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-8">
                <div className={styles.leftcheckoutable}>
                  <div className={styles.scrollTable}>
                    <table>
                      <thead>
                        <tr>
                          <th>Products</th>
                          <th>Pack</th>
                          <th>Quantity</th>
                          <th>Sub-Total</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className={styles.itmenImage}>
                              <span>
                                <img src={CheckoutIcon} alt="" />
                              </span>
                              <div>
                                <h5>{order?.name}</h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            {order.priceSymbol}{" "}
                            {(order?.planPrice / order?.quantity).toFixed(2)}
                          </td>
                          <td>
                            <div className={styles.pliceselect}>
                              <div className={styles.gormGroup}>
                                <span
                                  className={styles.decrement}
                                  onClick={decreaseQuantity}
                                >
                                  -
                                </span>
                                <p className="mb-0">{order.quantity}</p>
                                <span
                                  className={styles.increment}
                                  onClick={increaseQuantity}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            {order.priceSymbol}{" "}
                            {order.planPrice && order?.planPrice.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {finalPrice > 0 && (
                    <div className={styles.chosepayment}>
                      <h6>Choose Payment Method</h6>
                      {paymentMethodError && selectedPaymentMethod === 0 && (
                        <span className="error">{paymentMethodError}</span>
                      )}
                      <ul>
                        {/* <li onClick={() => handlePaymentMethod(1)}>
                        <span> */}
                        {/* {isMacOrIOS &&
                            <img src={ApplePayIcon} alt="applePay" />
                          }
                          {isMacOrIOS &&
                            "Apple Pay"
                          } */}

                        {/* <img src={Gpay} alt='googlePay' className={styles.Gpay} />
                          Google Pay
                        </span>
                        {selectedPaymentMethod === 1 && <button className={styles.selected}>Selected</button>}</li> */}
                        {/* {paymentGateway == PAYMENT_METHOD.STRIPEPAY && ( */}

                        {isAndroidOrIOS && (
                          <li onClick={() => handlePaymentMethod(4)}>
                            <span>
                              <img src={CreditCardPayIcon} alt="creditCard" />
                              <div className={styles.visass}>
                                {/Android/i.test(navigator.userAgent) ? "Google Pay" : "Apple Pay"}
                              </div>
                            </span>
                            <label>
                              {" "}
                              {selectedPaymentMethod === 4 && (
                                <button className={styles.selected}>
                                  Selected
                                </button>
                              )}
                              {/* <i className="fas fa-chevron-right"></i> */}
                            </label>
                          </li>
                        )}

                        <li onClick={() => handlePaymentMethod(2)}>
                          <span>
                            <img src={CreditCardPayIcon} alt="creditCard" />
                            <div className={styles.visass}>
                              Credit / Debit Card{" "}
                              <p>Visa, Mastercard, AMEX, CUP, JCB</p>
                            </div>
                          </span>
                          <label>
                            {" "}
                            {selectedPaymentMethod === 2 && (
                              <button className={styles.selected}>
                                Selected
                              </button>
                            )}
                            {/* <i className="fas fa-chevron-right"></i> */}
                          </label>
                        </li>
                        {/* )} */}
                        {paymentGateway == PAYMENT_METHOD.RAZORPAY && (
                          <li onClick={() => handlePaymentMethod(3)}>
                            <span>
                              <img src={CreditCardPayIcon} alt="creditCard" />
                              <div className={styles.visass}>
                                Razorpay<p>Visa, Mastercard, AMEX, CUP, JCB</p>
                              </div>
                            </span>
                            <label>
                              {" "}
                              {selectedPaymentMethod === 3 && (
                                <button className={styles.selected}>
                                  Selected
                                </button>
                              )}
                              {/* <i className="fas fa-chevron-right"></i> */}
                            </label>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.rightCheckout}>
                  <div className={styles.payemntSummery}>
                    <h6>Payment Summary</h6>
                    <ul>
                      <li>
                        <span>Sub-total</span>{" "}
                        <label>
                          {order.priceSymbol}{" "}
                          {order?.planPrice && order.planPrice.toFixed(2)}
                        </label>
                      </li>
                      {/* <li>
                                            <span>Shipping</span> <label>$Free</label>
                                        </li> */}
                      {order.discountPrice ? (
                        <li>
                          <span>Discount</span>{" "}
                          <label>
                            {order?.priceSymbol} {order.discountPrice}
                          </label>
                        </li>
                      ) : null}
                      {/* <li>
                                            <span>Tax</span> <label>$00</label>
                                        </li> */}
                      <li className={styles.Total}>
                        <span>Total</span>
                        <label>
                          {order?.priceSymbol}{" "}
                          {order.finalPrice && order.finalPrice.toFixed(2)}
                        </label>
                      </li>
                    </ul>
                    <button
                      onClick={
                        isAuthenticated
                          ? () => handleCheckout()
                          : () => {
                            const niyoToken =
                              window.localStorage.getItem("niyoToken");
                            if (!niyoToken) {
                              window.localStorage.setItem(
                                "current_url",
                                `/checkout/${params.id}`
                              );
                              navigate(`/login`);
                            } else {
                              handleCheckout();
                            }
                          }
                      }
                      disabled={urlToken && secureToken?.error ? true : false}
                    >
                      Proceed to Checkout <i className="fas fa-arrow-right"></i>
                    </button>
                  </div>

                  {!urlToken &&
                    promos?.length &&
                    order?.couponApplied === false ? (
                    <div className={styles.PromoCode}>
                      <h6>Apply Promo Code</h6>
                      <p>You can apply your promo code with your purchase</p>
                      <div className={styles.formGroup}>
                        <form onSubmit={handleSearchSubmit}>
                          <input
                            type="text"
                            placeholder="Enter Promo Code"
                            value={promoCode}
                            onChange={handleSearchSubmit}
                            style={{ color: "#ffffff" }}
                          />
                          <button type="submit">Apply</button>
                        </form>
                      </div>
                      <h6>Available Promo codes</h6>
                      {promoError && (
                        <span className="error">{promoError}</span>
                      )}
                      {promos &&
                        promos?.length > 0 &&
                        promos.map((promoDetails: any, index: any) => (
                          <div className={styles.couponlist} key={index}>
                            <div className={styles.couponlisttop}>
                              <span className="text-white">
                                {promoDetails?.promoCode}
                              </span>
                              <button
                                onClick={() =>
                                  handleAddPromo({
                                    promo: promoDetails,
                                    minimumSpend: promoDetails?.minimumSpend,
                                  })
                                }
                              >
                                {order?.promoApplied === false ||
                                  order?.promoId !== promoDetails?._id
                                  ? "Apply"
                                  : "Remove"}
                              </button>
                            </div>
                            <h5 className="text-white">
                              {promoDetails?.title}
                            </h5>
                            <p>
                              Expires{" "}
                              {moment(promoDetails?.endDate).format(
                                "MMM Do, YYYY"
                              )}
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>

        {
          // selectedPaymentMethod === 2 || selectedPaymentMethod === 1 &&
          show && (
            <StripePaymentModal
              show={show}
              {...paymentDetails}
              closeModal={() => setShow(false)}
              paymentMethod={selectedPaymentMethod}
              siteDomain={window.localStorage.getItem("niyoToken") as string}
            />
          )
        }
      </div>
    </Layout>
  );
};

export default Checkout;
