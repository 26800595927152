import pks from "../../../assets/images/pks.png"
import showone from "../../../assets/images/showone.png"
import showthree from "../../../assets/images/showthree.png"
import FeaturesShowcasseCard from "./FeaturesShowcasseCard"
import styles from "../styles/style.module.css";

const FeaturesShowcase = () => {
    return (
        <div className={styles.showcasse}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className={styles.showcaseLeft}>
                            <h6>Experience Connectivity Unleashed</h6>
                            <h3>Commbitz eSIM Feature Showcase</h3>
                            <p>Traveling is an enriching experience that opens up new horizons, exposes us to different cultures, and creates memories that last a lifetime. </p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className={styles.showcaseRight}>
                            <img src={pks} alt="" />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <FeaturesShowcasseCard showSrc={showone} />
                    <FeaturesShowcasseCard showSrc={showone} />
                    <FeaturesShowcasseCard showSrc={showthree} />
                </div>
            </div>
        </div>
    )
}

export default FeaturesShowcase;